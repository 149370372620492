import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import HeaderImage from 'components/HeaderImage'
import RoomPage from 'components/RoomPage'
import { formatNodes } from 'utils/formatNodes'

function WindowPage({data}) {
	const recommended = formatNodes(data.allStripeSku)
  const pageData = {
    header: {
      title: "Plants for the Window",
      copy: "",
      image: data.windowsill.childImageSharp.fluid
    },
    recommended: {
      title: "Top Picks",
      cta: {
        title: "View all window plants",
        link: "/plants?window"
      },
      items: recommended.slice(0,8)
    },
    sections: [
      {
        title:"Plants for the kitchen",
        image: data.windowsill.childImageSharp.fluid,
        items: recommended.slice(0,6)
      },
      {
        title: "Plants for the kitchen",
        image: data.windowsill.childImageSharp.fluid,
        items: recommended.slice(0,6)
      }
    ]
  }
  return <RoomPage data={pageData}/>
}

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants for the window sill"/>
      <WindowPage data={data}/>
    </>
  )
}

export const query = graphql`
  query WindowQuery {
    windowsill: file(relativePath: { eq: "window/sill.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allStripeSku(filter: { metadata: { bedroom: { in: "True" } } } ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
  }
`